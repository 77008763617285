<template>
    <div id="app">
        <div style="margin: 0 auto;height: auto;background: #FFFFFF">
            <div style="padding-top: 115px;">
                <div style="height: 80px;width: 1500px;position: relative;margin: 0 auto">
                    <div style="width: 1150px;float: left;background-color: white;border-radius: 5px;border: 2px solid #FF9800;">
                        <input v-model="like" type="" name="" placeholder="请输入你想要查询的内容以获得服务帮助......" style="height: 40px;width: 70% ;border: none;float: left;margin-top: 5px;font-size: 16px;padding-left: 20px;outline: none;">
                        <div style="height: 50px;width: 110px;float: right;cursor: pointer;">
                            <div style="background-color: #FF9800; height: 50px;width: 110px;border-radius: 5px;display: flex;justify-content: center; align-items:center;font-size: 20px"><svg t="1673238556897" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2759" width="25" height="25"><path d="M652.519 165.796c-134.397-134.395-352.323-134.395-486.721 0-134.397 134.41-134.397 352.332 0 486.727 130.861 130.858 340.852 134.249 475.878 10.316L937.84 959 959 937.841 662.835 641.666C786.784 506.657 783.379 296.655 652.519 165.796zM631.358 631.363c-122.517 122.515-321.884 122.515-444.4 0-122.517-122.514-122.517-321.878 0-444.393 122.517-122.529 321.884-122.529 444.4 0C753.875 309.485 753.875 508.849 631.358 631.363z" p-id="2760" fill="#707070"></path></svg>搜索</div>
                        </div>
                    </div>
                    <div style="width: 774px;height: 21px;font-size: 18px;color: #999999;float: left;"></div>
                    <div style="clear: both;">
                        <div @click="name=1" :style= "name == 1?'height: 50px;color:#E31436;border: 1px solid #E31436;':''" style="height: 50px;background: #FFFFFF;border: 1px solid #DCDCDC;float: left;margin-top: 10px;float: left;cursor: pointer">
                            <div style="width: 72px;height: 21px;font-size: 18px;margin: 11px 20px;text-align: center">默认推荐</div>
                        </div>
                        <div @click="name=2" :style= "name == 2?'height: 50px;color:#E31436;border: 1px solid #E31436;':''" style="height: 50px;background: #FFFFFF;border: 1px solid #DCDCDC;float: left;margin-top: 10px;float: left;cursor: pointer">
                            <div style="width: 72px;height: 21px;font-size: 18px;margin: 11px 20px;text-align: center">销量优先</div>
                        </div>
                        <div @click="name=3" :style= "name == 3?'height: 50px;color:#E31436;border: 1px solid #E31436;':''" style="height: 50px;background: #FFFFFF;border: 1px solid #DCDCDC;float: left;margin-top: 10px;float: left;cursor: pointer">
                            <div style="width: 72px;height: 21px;font-size: 18px;margin: 11px 20px;text-align: center">好评最多</div>
                        </div>
                        <div @click="name=4" :style= "name == 4?'height: 50px;color:#E31436;border: 1px solid #E31436;':''" style="height: 50px;background: #FFFFFF;border: 1px solid #DCDCDC;float: left;margin-top: 10px;float: left;cursor: pointer">
                            <div style="width: 72px;height: 21px;font-size: 18px;margin: 11px auto;text-align: center">收藏</div>
                        </div>
                        <div style="width: 303px;height: 50px;background: #FFFFFF;border: 1px solid #DCDCDC;float: left;margin-top: 10px">
                            <div style="width: 72px;height: 21px;font-size: 18px;color: #444444;margin: 11px 0 0 40px;float: left">服务商家</div>
                            <div style="float: left;width: 121px;height: 30px;margin: 6px 0 0 30px;">
                                <el-select v-model="value" placeholder="请选择">
                                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                                </el-select>
                            </div>
                        </div>
                        <div style="width: 400px;height: 50px;background: #FFFFFF;border: 1px solid #DCDCDC;float: left;margin-top: 10px;">
                            <div style="height: 21px;font-size: 18px;color: #444444;margin: 11px 0 0 40px;float: left">服务提供地</div>
                            <div style="width: 218px;height: 36px;float: left;margin: 7px;text-align: center">
                                <address-selector style="width: 218px;height: 36px;" @change="selectCity" :address ="selectedAddress"  :lv="3" :info="false" size="small" :multiple="false"></address-selector>
                            </div>
                        </div>
                        <div style="width: 130px;height: 21px;font-size: 18px;color: #444444;float: left;margin: 23px 0 0 37px;">店铺共<span style="color:#E31436">{{AllShop.length}}</span>个</div>
                        <div v-for="(item,index) in AllShop" :key="index" style="float: left;">
                            <div style="width: 1500px;height: 250px;background: #FFFFFF;margin-top: 5px;padding: 20px 40px">
                                <!-- 左侧 -->
                                <div style="float: left">
                                    <div style="width: 62px;height: 62px;float: left">
                                        <img :src="item.logo.img_url" style="border-radius: 50%;width: 62px;height: 62px;">
                                    </div>
                                    <div style="float: left;">
                                        <div style="height: 21px;font-size: 16px;float: left;margin: 2px;padding-left: 5px"> {{item.name}}</div>
                                        <div style="float: left;margin:3px 5px;">
                                            <svg t="1681700453847" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3290" width="15" height="15">
                                                <path d="M511.913993 63.989249C317.882076 63.989249 159.973123 221.898203 159.973123 415.930119c0 187.323366 315.473879 519.998656 328.890979 534.103813 6.020494 6.364522 14.449185 9.976818 23.221905 9.976818 0.172014 0 0.516042 0 0.688056 0 8.944734 0 17.545439-4.128339 23.393919-11.008903l109.22896-125.054258c145.179909-177.690576 218.629934-314.957836 218.629934-407.845456C864.026877 221.898203 706.117924 63.989249 511.913993 63.989249zM511.913993 575.903242c-88.415253 0-159.973123-71.55787-159.973123-159.973123s71.55787-159.973123 159.973123-159.973123 159.973123 71.55787 159.973123 159.973123S600.329246 575.903242 511.913993 575.903242z" fill="#FF9800" p-id="3291"></path>
                                            </svg>
                                        </div>
                                        <div style="margin:3px 5px;">
                                            <div style="float: left;font-size: 15px;" v-for="(type,name) in item.city.cityName" :key="name">{{type}}</div>
                                        </div>
                                        <div style="clear: both;padding-left: 2px;">
                                            <div style="float: left;margin: 5px;">
                                                <div style="background: #2970FF;border-radius: 2px;width: 38px;height: 20px;">
                                                    <div v-if="item.type == 1" style="height: 16px;font-size: 14px;color: #FFFFFF;margin: 5px">个人</div>
                                                    <div v-if="item.type == 2" style="height: 16px;font-size: 14px;color: #FFFFFF;margin: 5px">企业</div>
                                                </div>
                                            </div>
                                            <div style="float: left;margin: 5px;">
                                                <div style="background: #7BD7A2;border-radius: 2px;width: 68px;height: 20px;">
                                                    <div style="height: 16px;font-size: 14px;color: #FFFFFF;margin: 5px">实名认证</div>
                                                </div>
                                            </div>
                                            <div style="float: left;margin: 5px;">
                                                <div style="background: #FF9800;border-radius: 2px;width: 70px;height: 20px;">
                                                    <div style="height: 16px;font-size: 14px;color: #FFFFFF;margin: 5px">10年老店</div>
                                                </div>
                                            </div>
                                            <div style="color: #999999;font-size: 15px;height: 18px;float: left;margin-top: 10px;">店铺成立时间：<span style="color: #2970FF">2013年</span></div>
                                        </div>
                                    </div>
                                    <div style="clear: both;padding-top: 30px;">
                                        <div style="color: #999999; margin: 0 10px;float: left">
                                            <span>近半年成交额：</span>
                                            <span style="color: #2970FF">45万</span>
                                        </div>
                                        <div style="color: #999999; margin: 0 10px;float: left">
                                            <span style="font-weight: 600;"> | </span>
                                            <span style="margin-left: 10px">店铺好评率：</span>
                                            <span style="color: #2970FF">98%</span>
                                        </div>
                                        <div style="color: #999999; margin: 0 10px;float: left">
                                            <span style="font-weight: 600;"> | </span>
                                            <span style="margin-left: 10px">注册资本：</span>
                                            <span style="color: #2970FF">1,241,124万</span>
                                        </div>
                                    </div>
                                    <div style="clear: both;color: #999999;padding: 16px 10px;width: 800px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap; ">店铺介绍：{{item.introduce}}</div>
                                    <div style="clear: both;margin-top: 10px;margin-left: 10px;">
                                        <div style="float: left" @click="skip_shop(item.id)"><el-button plain>进店看看</el-button></div>
                                        <div style="float: left;margin-left: 20px;"><el-button type="warning" class="el-icon-chat-dot-round">免费咨询</el-button></div>
                                    </div>
                                </div>
                                <div style="float: right">
                                   <div v-for="(label,video) in case_list" :key="video"  style="width: 300px;height: 260px;border: 1px solid #DCDCDC;float: left;margin-right: 30px">
                                        <div style="width: 300px;height: 180px;">
                                            <img :src="label.url" style="width: 300px;height: 180px;">
                                        </div>
                                       <div style="font-size: 18px;margin: 5px 10px;height: 21px;">{{label.title}}</div>
                                       <div style="font-size: 16px;color: #999999;height: 18px;margin-left: 10px">{{label.content}}</div>
                                   </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AddressSelector from "@/components/common/city/AddressSelect.vue";

export default{
    components: {
        AddressSelector
    },
    computed: {

    },
    data () {
        return {
            like:"",
            name:1,
            value:'',
            options:[],
            selectedAddress: {
                cityId : [],
                cityName : [],
                address:""
            },
            AllShop:[],
            case_list:[{url:'https://oss.baigongbao.com/img/v2_psaxco.png',content:'经过新土地团队的深入谈论，设计设计...',title:'阿孜汗村特色乡村IP形象设计'},{url:'https://oss.baigongbao.com/img/v2_psaxco.png',content:'经过新土地团队的深入谈论，设计设计...',title:'阿孜汗村特色乡村IP形象设计'}]
        }
    },
    mounted:function(){
        this.getAllShop()
    },
    methods:{
        selectCity(value) {
            this.selectedAddress = value;
        },
        getAllShop(){
            let that = this;
            that.newApi.getAllShop({}).then((ret)=>{
                that.AllShop = ret.data;
            }).catch((err)=>{
                console.log(err)
            })
        },
        // 跳转店铺
        skip_shop(id){
            this.$router.push({path:'/shop/shopCode',query:{id:id}})
        },
    }
}
</script>

<style scoped>
.tag_list {
    overflow:hidden;
}

</style>

